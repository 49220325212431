import React, {StrictMode, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function AuthSuccess(props) {

    if (props.message === '') {
        return null;
    }

    return (
        <div>

            <div className="row">
                <div className="col-md-8">
                    <div className="error alert alert-success">
                        {props.message}
                    </div>
                </div>
            </div>

        </div>


);
}

export default AuthSuccess;