import React, {useEffect, useState} from 'react';
import axios from "axios";
import PhoneCheckPopup from "./PhoneCheckPopup";

function PhoneCheck() {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        axios.get('/local/components/ctweb/sms.authorize/ajax.php?method=phoneValidate'
        ).then(response => {
            if (response.data.result === 'phone_is_empty') {
                if (!window.location.href.includes('/personal/private')) {
                    setVisible(true)
                }
            }}
        )
    }, []);
    if (visible) {
        return (
            <PhoneCheckPopup/>
        );
    }
}

export default PhoneCheck;