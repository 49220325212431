import React from 'react';
import AuthorizationForm from "../ctweb/AuthorizationForm";

function PhoneCheckPopup() {
    return (
        <div className="flex items-center justify-center fixed z-40 h-screen w-screen dark:bg-fancyboxDark top-0 left-0
        bg-fancybox overlay-box">
            <div className={"md:rounded-xl dark:bg-darkBox bg-white rounded-0 relative z-50 p-8 m-auto md:max-w-md md:w-full w-screen md:h-auto h-screen  block"}>
                <AuthorizationForm
                    title={"Подтвердите номер телефона."}
                    isChangePhone={true}
                />
            </div>
        </div>
    );
}

export default PhoneCheckPopup;