import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";
import CodeForm from "./CodeForm";
import EmailForm from "./EmailForm";
import PropTypes from "prop-types";
import BackButton from "./BackButton";
import ReuseCode from "./ReuseCode";
import SendCodeAgain from "./SendCodeAgain";
import Email from "./Email";
import CodeNotResponse from "./CodeNotResponse";
import EmailRegister from "./EmailRegister";
import PhoneAuth from "./PhoneAuth";

AuthorizationForm.propTypes = {
    onValidate: PropTypes.func,
};

function AuthorizationForm(props) {

    const [phone, setPhone] = useState('')
    const [typeAuth, setTypeAuth] = useState('phone')

    const [type, setType] = useState('')
    const [errors, setErrors] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [reuseTime, setReuseTime] = useState(0)

    const [params, setParams] = useState({
        'codeLength': 0,
        'tg': {
            'name': '',
            'url': '',
            'enabled': 'N',
        }
    })

    const [step, setStep] = useState(authSteps.STATE_PHONE_WAITING)

    const rootForm = useRef();

    const onEndTimer = () => {
        setStep(authSteps.STATE_CODE_REUSED);
        setReuseTime(0)
    }

    const setFormVisible = (type) => {
        setTypeAuth(type)
    }

    useEffect(() => {

        if (step === authSteps.STATE_PHONE_WAITING) {
            axios.post('/local/components/ctweb/sms.authorize/ajax.php', {
                    'method' : 'initialize',
                    'is_ajax_post': 'Y',
                    sessid: BX.message("bitrix_sessid"),

                }, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                }
            ).then(response => {
                if (response.status===200) {
                    setStep(response.data.STEP)

                    if (response.data.USER_VALUES.PROVIDER_TYPE !== null ) {
                        if (response.data.USER_VALUES.PROVIDER_TYPE === 'SMS') {
                            setType('SMS')
                        } else {
                            setType('TELEGRAM')
                        }

                    }

                    setParams({
                        'codeLength': response.data.CODE_LENGTH,
                        'tg': response.data.TG_CONFIG ? {
                            'name': response.data.TG_CONFIG.NAME,
                            'url': response.data.TG_CONFIG.URL,
                            'enabled': response.data.TG_CONFIG.ENABLED,
                        } :  {
                            'name': '',
                            'url': '',
                            'enabled': 'N',
                        }
                    });

                    setReuseTime(response.data.REUSE_TIME ?? 0)
                    setPhone(response.data?.USER_VALUES.PHONE ?? '')
                } else {
                    alert('Error get init params')
                }
            })
        }

    }, []);

    return (
        <>
            <div ref={rootForm} className={"h-full flex md:block " + (props.isRegister || props.isChangePhone?
                "" : "items-center justify-center")}>
                <div>
                    <div className="flex flex-col mb-4">
                        <p className="text-center font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg">
                            {props.title ?? "Войдите или зарегистрируйтесь"}
                        </p>
                        <CodeForm setStep={setStep} step={step} phone={phone} onEndTimer={onEndTimer}
                                  setType={setType} type={type}
                                  errors={errors} setErrors={setErrors}
                                  typeAuth={typeAuth} rootForm={rootForm}
                                  setLoaded={setLoaded} loaded={loaded} reuseTime={reuseTime} setReuseTime={setReuseTime}
                                  isRegister={props.isRegister} onValidate={props.onValidate} params={params}
                                  isChangePhone={props.isChangePhone}
                        />
                        <EmailForm typeAuth={typeAuth}
                                   setStep={setStep} step={step} rootForm={rootForm} loaded={loaded}
                                   setLoaded={setLoaded}/>

                        <div className="flex flex-col w-full">
                            {
                                typeAuth === 'mail' ?
                                    <PhoneAuth setFormVisible={setFormVisible}/>
                                    : <Email setFormVisible={setFormVisible} isRegister={props.isRegister} isChangePhone={props.isChangePhone}/>
                            }

                            <EmailRegister isRegister={props.isRegister} isChangePhone={props.isChangePhone}/>
                        </div>
                    </div>
                </div>
            </div>
    </>

);
}

export default AuthorizationForm;