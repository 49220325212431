import {createRoot} from "react-dom/client";
import React, {StrictMode} from "react";
import AuthorizationForm from "../components/ctweb/AuthorizationForm";
import PhoneCheck from "../components/phoneCheck/PhoneCheck";
import AuthorizationDialog from "../components/ctweb/AuthorizationDialog";

document.addEventListener("DOMContentLoaded", function () {
    const nodePhone = document.getElementById('js-register-form-field-phone');
    if (nodePhone) {

        const phone = createRoot(nodePhone)

        function onRegister() {

            const form = nodePhone.closest('form[name="auth"]')

            const userEmail = form.querySelector('input[name="EMAIL"]');
            if (!userEmail.reportValidity()) {
                return 'Некорректно заполнен e-mail'
            }

            const userPersonalBirthday = form.querySelector('input[name="PERSONAL_BIRTHDAY"]');
            if (!userPersonalBirthday.reportValidity()) {
                return 'Некорректно заполнена дата рождения'
            }

            const currentPersonalBirthday = userPersonalBirthday.value;
            if (currentPersonalBirthday.length === 10) {

                const [day, month, year] = currentPersonalBirthday.split('/').map(Number);

                const personalBirthDay = new Date(year, month - 1, day);

                const today = new Date();
                const date18YearsAgo = new Date();
                date18YearsAgo.setFullYear(today.getFullYear() - 18);
                if (personalBirthDay > date18YearsAgo) {
                    return "Регистрация на данном сайте разрешена лицам старше 18 лет"
                }
            } else {
                return "Не верный формат даты рождения"
            }

            const userPassword = form.querySelector('input[name="PASSWORD"]');
            if (!userPassword.reportValidity()) {
                return 'Некорректно заполнен пароль'
            }

            const userConfirmPassword = form.querySelector('input[name="CONFIRM_PASSWORD"]');
            if (userConfirmPassword.value !== userPassword.value) {
                return 'Пароль и подтверждения пароля не совпадают'
            }

            const userRules = form.querySelector('input[name="USER_RULES"]');
            if (!userRules || !userRules.checked) {
                return 'Необходимо принять пользовательское соглашение'
            }

            const userPolitics = form.querySelector('input[name="USER_POLITICS"]');
            if (!userPolitics || !userPolitics.checked) {
                return 'Необходимо принять политику конфиденциальности'
            }

            return undefined
        }

        phone.render(
            <StrictMode>
                <div className="flex flex-col mb-3">
                    <div className="mt-8 flex flex-col items-center xl:py-10 xl:px-8 py-5 px-5 bg-textDark rounded-xl dark:bg-darkBox mb-7">
                        <AuthorizationForm title={"Подтвердите номер для регистрации"} onValidate={onRegister} isRegister={true}/>
                    </div>
                </div>
            </StrictMode>
        )
    }

    const nodePhoneChange = document.getElementById('js-profile-change-number');
    if (nodePhoneChange) {
        const phone = createRoot(nodePhoneChange)
        phone.render(
            <StrictMode>
                <div className="w-fit mt-8 flex flex-col items-center xl:py-10 xl:px-8 py-5 px-5 bg-textDark rounded-xl dark:bg-darkBox mb-7">
                    <AuthorizationForm  title={"Изменить номер телефона."} isChangePhone={true}/>
                </div>
            </StrictMode>
        )
    }

    const nodePhoneCheck = document.getElementById('js-popup-phone-check');
    if (nodePhoneCheck) {
        const popup = createRoot(nodePhoneCheck)
        popup.render(
            <StrictMode>
                <PhoneCheck/>
            </StrictMode>
        )
    }
});

const node = document.getElementById('js_auth_block');
if (node) {
    const auth = createRoot(node)
    const authBtn = document.getElementById('auth-button')
    if (authBtn) {
        authBtn.addEventListener("click", function () {

            document.querySelector('.overlay-box').classList.toggle('hidden');

            auth.render(
                <StrictMode>
                    <AuthorizationDialog hash={+ new Date()} />
                </StrictMode>
            )
        });
    }
}