import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";
import EmailLogin from "./EmailLogin";
import EmailPassword from "./EmailPassword";
import EmailError from "./EmailError";
import Checkbox from "./Checkbox";
import EmailSubmit from "./EmailSubmit";
import PhoneAuth from "./PhoneAuth";
import EmailRegister from "./EmailRegister";

function EmailForm(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState('')

    return (
        <form id="ctweb-smsauth_form_email"
              action="/bitrix/components/ctweb/sms.authorize/ajax.php"
              method="POST"
              className={"ctweb-smsauth-menu-step mb-4 flex justify-center flex-col "
                  + (props.typeAuth === 'mail' ? "flex" : " hidden")}>

            <div className="mb-4 flex justify-center">
                <div className="max-w-xs">
                    <div className="form-group flex flex-col">
                        <input type="hidden" name="sessid" value={BX.message("bitrix_sessid")}/>
                        <input type="hidden" name="METHOD" placeholder="" value="EMAIL_AUTH"/>
                        <label className="text-xs font-normal text-textLight mb-2 dark:text-textDarkLightGray">
                            Логин
                        </label>
                        <EmailLogin setEmail={setEmail} email={email}/>
                        <div className="flex flex-row justify-between items-center">
                            <label
                                className="text-xs dark:font-normal font-normal text-textLight dark:text-textDarkLightGray">
                                Пароль
                            </label>
                            <span /*style={{float: right}}*/>
                                <a href="/auth/?forgot_password=yes" className="ctweb-link ctweb-link-fargot font-medium
                                text-xs text-light-red hover:underline dark:text-textDarkLightGray">
                                    Забыли пароль?
                                </a>
                            </span>
                        </div>
                        <EmailPassword setPassword={setPassword}/>
                        <EmailError errors={errors}/>
                    </div>
                    <Checkbox/>
                </div>
            </div>
            <div className="ctweb-button-block flex items-center justify-center flex-col">
                <EmailSubmit email={email} password={password} setStep={props.setStep} step={props.step}
                              setErrors={setErrors} rootForm={props.rootForm} setLoaded={props.setLoaded} loaded={props.loaded}/>
            </div>
        </form>
);
}

export default EmailForm;